<mat-expansion-panel hideToggle class="gt-mat-expansion-titlebar-panel" [expanded]="true">
  <mat-expansion-panel-header #InvoiceLines (click)="InvoiceLines._toggle()" style="height: fit-content !important;">
    <mat-panel-title role="heading" aria-level="2">
      <div class="gt-mat-panel-titlebar-title-container full-width">
        <div class="gt-mat-panel-titlebar-title-icon-container" id="gt-panel-title">
          <mat-form-field appearance="legacy" class="gt-form-field-without-label gt-quicksearch-input filterWidth"
            autocomplete="off" floatLabel="always">
            <mat-label id="gt-mat-label-1"></mat-label>
            <input matInput [(ngModel)]="searchText" (keyup)="onFilterTextChange()" autocomplete="off"
              [disabled]="isDisabled" aria-labelledby="gt-mat-label-1"
              placeholder="{{'spdf.app.module.buup.searchIcon' | translate}}">
            <button class="gt-form-field-clear" mat-icon-button matSuffix *ngIf="searchText" [disabled]="isDisabled"
              (click)="clearSearchText()" matTooltip="{{'spdf.app.tooltips.clear' | translate}}"
              attr.aria-label="{{'spdf.app.tooltips.clear' | translate}}">
              <mat-icon> cancel </mat-icon>
            </button>

            <button mat-icon-button matSuffix disabled class="gt-search-icon"
              attr.aria-label="{{'spdf.app.module.buup.searchIcon' | translate}}">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="gt-mat-panel-subtitle-container">
          <span class="gt-panel-subtitle mat-body-1 total-sum-font">
            {{'spdf.app.module.invoice.lines.total.sum.text' | translate}}
            <span class="total-amount-font">{{totalSum}}</span>
          </span>
        </div>
      </div>
    </mat-panel-title>
    <mat-panel-description class="mat-panel-descripton">
      <div class="gt-mat-panel-actions-buttons-group">
        <button mat-icon-button id="addLineItemButton" color="primary" [disabled]="isDisabled"
          (click)="$event.stopPropagation(); addRow()" matTooltip="{{'spdf.app.tooltips.add' | translate}}">
          <mat-icon fontSet="far" fontIcon="fa-plus">
          </mat-icon>
        </button>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div id="line-items-grid">
    <gt-editable-grid [config]="gridConfig"></gt-editable-grid>
  </div>
</mat-expansion-panel>