import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError, lastValueFrom } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AppConfig } from '../app.config';
import { CommonService } from './common.service';
import { CacheAPIService } from './cacheApi.service';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    reqObj = {};
    headers: any;
    constructor(
        private commonService: CommonService,
        private cacheAPIService: CacheAPIService,
        private http: HttpClient) {
    }

    // Error handling
    errorHandler(error: any) {
        let err = {
            errorMessage: '',
            errorCode: ''
        };
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            err.errorMessage = error.error.error;
        } else {
            err.errorCode = error.status;
            err.errorMessage = error.error.error;
        }
        console.log(err.errorMessage);
        return throwError(() => error);
    }

    // get business units
    async GetBusinessUnits() {
        let data = await this.http.get<any>(AppConfig.APP_BASE_API_URL + AppConfig.GET_BUSINESS_UNITS)
            .pipe(
                await catchError(this.errorHandler)
            );
        return await lastValueFrom(data);
    }

    // get task & document data
    async GetTask(request: any) {
        let data = await this.http.get<any>(AppConfig.APP_BASE_API_URL + AppConfig.GET_TASK +
            '/' + request.configurationId + '/' + request.receivingEmail + '/' + request.unitUuid
            + '/' + request.invoiceAddress)
            .pipe(
                await catchError(this.errorHandler)
            );
        return await lastValueFrom(data);
    }

    // get task & document data
    async GetOcrData(request: any) {
        let data = await this.http.get<any>(request.ocrUrl)
            .pipe(
                await catchError(this.errorHandler)
            );
        return await lastValueFrom(data);
    }

    // skip task
    async SkipTask(request: any) {
        let data = await this.http.post<any>(AppConfig.APP_BASE_API_URL + AppConfig.SKIP_TASK, request)
            .pipe(
                await catchError(this.errorHandler)
            );
        return await lastValueFrom(data);
    }

    // process task
    async SendTaskToProcess(request: any) {
        let data = await this.http.post<any>(AppConfig.APP_BASE_API_URL + AppConfig.PROCESS_TASK, request)
            .pipe(
        );
        return await lastValueFrom(data);
    }

    //initialize user settings
    UserAppInitializer() {
        let promise: any = this.http.get<any>(AppConfig.APP_BASE_API_URL + AppConfig.INITIALIZE)
            .pipe(
                catchError(this.errorHandler)
            ).toPromise();
        return promise;
    }

    // get business unit users
    async GetBusinessUnitUsers() {
        let data = await this.http.get<any>(AppConfig.APP_BASE_API_URL + AppConfig.BUSINESS_UNIT_USERS + '/' + this.commonService.getBaswareCustomerId())
            .pipe(
                await catchError(this.errorHandler)
            );
        return await lastValueFrom(data);
    }

    // get all users
    async GetAllUsers() {
        let data = this.http.get<any>(AppConfig.APP_BASE_API_URL + AppConfig.GET_ALL_USERS + '/' + this.commonService.getBaswareCustomerId())
            .pipe(
                catchError(this.errorHandler)
            );
        return await lastValueFrom(data);
    }

    // get business units
    putBusinessUnitUsers(body: any) {
        let data = this.http.put<any>(AppConfig.APP_BASE_API_URL + AppConfig.BUSINESS_UNIT_USERS + '/' + this.commonService.getBaswareCustomerId(), body)
            .pipe(
                catchError(this.errorHandler)
            );
        return data;
    }

    // get supplier data 
    GetSupplierData(url: string) {
        return this.cacheAPIService.getCachedOrFetch(url, false).pipe(
            catchError(this.errorHandler)
        );
    }

    // Download invoice 
    DownloadInvoice(url: string) {
        window.open(url)
    }

    // extend session
    async ExtendSession(request: any) {
        let data = await this.http.post<any>(AppConfig.APP_BASE_API_URL + AppConfig.EXTEND_SESSION, request)
            .pipe(
                await catchError(this.errorHandler)
            );
        return await lastValueFrom(data);
    }

    // fetch email
    async GetEmailData(bumId: string) {
        let url = AppConfig.APP_BASE_API_URL + AppConfig.EMAIL_DETAILS(bumId);
        let data = await this.http.get<any>(url)
            .pipe(
                retry(1),
                await catchError(this.errorHandler)
            );
        return await lastValueFrom(data);
    }

    getMultiInvoiceSettings(configId: string) {
        // return this.http.get<any>('/assets/settings.json');
        let data = this.http.get<any>(AppConfig.APP_BASE_API_URL + AppConfig.GET_INVOICE_SETTINGS_URL(this.commonService.getBaswareCustomerId(), configId))
            .pipe(
                catchError(this.errorHandler)
            );
        return data;
    }

    postMultiInvoiceSettings(body: any) {
        let data = this.http.post<any>(AppConfig.APP_BASE_API_URL + AppConfig.POST_INVOICE_SETTINGS_URL, body)
            .pipe(
                catchError(this.errorHandler)
            );
        return data;
    }

    updateMultiInvoiceSettings(body: any) {
        let data = this.http.put<any>(AppConfig.APP_BASE_API_URL + AppConfig.POST_INVOICE_SETTINGS_URL, body)
            .pipe(
                catchError(this.errorHandler)
            );
        return data;
    }

    deleteMultiInvoiceSettings(body: any) {
        let data = this.http.delete<any>(AppConfig.APP_BASE_API_URL + AppConfig.POST_INVOICE_SETTINGS_URL, { body: body })
            .pipe(
                catchError(this.errorHandler)
            );
        return data;
    }
    
    clearCacheApis(){
        this.cacheAPIService.clearCacheStorage();
    }

}